<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span class="foorterTxt">{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/homec.jpg?d='+new Date()*1 : '/img/footer/home.jpg?d='+new Date()*1"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/homec.jpg?d='+new Date()*1 : '/img/footer/home.jpg?d='+new Date()*1"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->
    <van-tabbar-item to="/Lottery">
      <span class="foorterTxt">{{ $t("foorter.subscribe") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/reserve.jpg?d='+new Date()*1 : '/img/footer/reserve.jpg?d='+new Date()*1"
          :alt="$t('foorter.subscribe')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/reserve.jpg?d='+new Date()*1 : '/img/footer/reserve.jpg?d='+new Date()*1"
          :alt="$t('foorter.subscribe')"
          :class="$t('foorter.subscribe')"
          style="height: 4rem"
          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    <!-- 中间 -->
    <!-- <van-tabbar-item to="/Choose">
      <span></span>
      <template #icon="props">
        <img class="tui"
          :src="props.active ? '/img/footer/beauty3.png?d='+new Date()*1 : '/img/footer/beauty3.png?d='+new Date()*1"
        />
      </template>
    </van-tabbar-item> -->
    <!-- 视频 -->
    <van-tabbar-item to="/Video">
      <span class="foorterTxt">{{ $t("foorter.video") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/videoc.jpg?d='+new Date()*1 : '/img/footer/video.jpg?d='+new Date()*1"
          :alt="$t('foorter.video')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/videoc.jpg?d='+new Date()*1 : '/img/footer/video.jpg?d='+new Date()*1"
          :alt="$t('foorter.video')"
          :class="$t('foorter.video')"
          style="height: 4rem"
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span class="foorterTxt">{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/myc.jpg?d='+new Date()*1 : '/img/footer/my.jpg?d='+new Date()*1"
          :alt="$t('foorter.my')"
          v-show="show !== 4"
        />
        <img
          :src="props.active ? '/img/footer/myc.jpg?d='+new Date()*1 : '/img/footer/my.jpg?d='+new Date()*1"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
          style="height: 4rem"
          v-show="show === 4"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 3;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 3;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 60px;
.van-tabbar {
  height: @tabbar-height;

  background-color: #b47d9c;
    color: #fff;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
.tui {
  width: 5rem;
  height: 5rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
.foorterTxt{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  font-size: 0.725rem;
  color: #fff;
}

.van-tabbar-item--active{
  background-color: #7e5678;
}
.van-tabbar-item--active .foorterTxt{
  color: #e6b98a;

}
</style>
