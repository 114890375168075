<template>
  <div class="container page">
    <van-nav-bar :title="this.videoInfo.vod_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="movie-video">
      <video id="my-video"   class="video-js" :class="is_see == 0?'noVip':''">
      </video>
    </div>
    <div class="movie-content">
      <div class="movie-descript">
        <p>{{ this.videoInfo.vod_name}}</p>
        <span>{{this.videoInfo.count}}{{ $t("video.num_play") }}</span>
      </div>

      <div class="movie-body">
        <div class="movie-title">
          <div>
            <span>{{ $t("index.recmonmand") }}</span>
          </div>
        </div>
        <div class="movie-list">
          <div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v,key) in moreVideoInfo" :key="key">
            <div>
              <img :src="v.vod_pic">
              <div>
                <div class="van-count-down">{{ v.time }}</div>
              </div>
            </div>
            <div>
              <p>{{ v.vod_name }}</p>
              <span>{{ v.count }}{{ $t("video.num_play") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="errorMoney" class="errorMoney" get-container="body" :close-on-popstate="true" :lock-scroll="true">
<div class="header">
{{$t('reservation.watchVideos')}} <van-icon class="close" name="cross" @click="errorMoney = false;" />
</div>
<div class="content">{{ errorMoneyTxt }}</div>
<!-- <div class="footer"></div> -->
    </van-popup>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  data() {
    return {
      nowPlayVideoUrl: "",
      cover:"",
      userInfo:[],
      videoInfo:{},
      moreVideoInfo:{},
      player:null,
      is_play:false,
      times: null,
      is_see: 0,
      free_see_time: 0,

      errorMoney: false,
      errorMoneyTxt:'',
      id:''
    };
  },
  methods: {
    back(){
      this.$router.push({path:'Home'})
    },
    getVideoInfo(){

      this.$http({
        method: 'get',
        data:{id:this.id},
        url: 'video_get_info'
      }).then(res=>{
        this.videoInfo = res.data;
        this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
        this.cover = this.videoInfo.vod_pic;
        let videos = document.getElementById('my-video');
        videos.poster = this.cover;
        this.getVideo();
      })

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {

      this.nowPlayVideoUrl = ''
        // this.$router.push({path:'?id='+id})
        this.id = id
        this.getInfo()
        // location.reload();
      }

    },
    getMoreVideoItem(){
      this.$http({
        method: 'get',
        url: 'video_get_more_item'
      }).then(res=>{
          this.moreVideoInfo = res.data;
      })
    },
    getVideo() {
      // this.player = videojs("my-video",  {
      //   height:"200px",
      //   preload: "auto", // 预加载
      //   controls: true,  // 显示播放的控件
      //   multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
      // });
      let that = this;
      this.player.src([{
        src: that.nowPlayVideoUrl,
        // type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
      }]);
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.is_see = this.userInfo.is_see;

          if (this.userInfo.is_see == 0) {

          //  this.toastMsg =  this.$toast({
          //     message:this.$t("video.buy"),
          //     duration:30000
          //  });

           this.errorMoneyTxt = this.$t("video.buy")
            this.errorMoney = true
          }
          this.free_see_time = this.userInfo.free_see_time;
          if(this.userInfo.status !== 1){
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({path:'/Login'})
          }else {
            if(this.$store.getters.getBaseInfo.isplay == 1){
              this.getVideoInfo();
              this.getMoreVideoItem()
              // if(this.userInfo.money <= "0.00"){
              //   this.$toast(this.$t("video.buy"));
              //   this.$router.push({path:'/Home'})
              // }
            }else {
              this.getVideoInfo();
              this.getMoreVideoItem();
            }
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getInfo(){
    const _this = this;
      this.player = videojs("my-video",  {
        height:"200px",
        preload: "auto", // 预加载
        controls: true,  // 显示播放的控件
        multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
        currentTime:0
      },function(){
        this.on("play", () => {
          // _this.player.currentTime(10)
          _this.is_play=true;
        });
      });
      this.getUserInfo();
      if(this.is_see == 0){

        this.times = setInterval(() => {
          if(this.is_play && this.is_see == 0){
            const ct = Math.round(this.player.currentTime())
            if(ct >= this.free_see_time){
              this.player.pause()

              this.errorMoneyTxt = this.$t("video.buy")
              this.errorMoney = true
              return
            }
          }
        }, 1000 * 2);
      }
      this.$once('hook:beforeDestroy', () => {
        _this.player.dispose();
      })
    }
  },
  mounted(){
    this.id = this.$route.query.id
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.player = null
      this.times = null
      this.getInfo()
     
    }

  },

  destroyed() {
    if (this.toastMsg) {
      this.toastMsg.close()
    }
    if(this.is_play){
      this.is_play = false;
    }
    this.player = null
    clearInterval(this.times);
  }
};
</script>

<style scoped lang="less">
.video-js {
  width: 100%;
  /*height: 420px;*/
  font-size: 24px;
}
.movie-content{
  flex: 1;
  overflow-y: auto;
}
.movie-content .movie-descript{
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.movie-content .movie-descript p{
  font-size: 30px;
  font-weight: 700;
  color: #e6c3a1;
}
.movie-content .movie-descript span{
  color: #979799;
}
.movie-content .movie-body{
  width: calc(100% - 20px);
  margin: 0 auto;
}
::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.movie-content .movie-body .movie-title{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie-content .movie-body .movie-title>div:first-child {
  width: 410px;
}
.movie-content .movie-body .movie-title>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #e6c3a1;
}
.movie-content .movie-body .movie-title>div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 25px;
  margin-right: 10px;
}
.movie-play-item{
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}
.movie-play-item>div{
  height: 100%;
}
.movie-play-item>div:first-child {
  width: 200px;
  position: relative;
}
.movie-play-item>div:first-child>img{
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.movie-play-item>div:first-child>div{
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0,0,0,.4);
  border-radius: 0 0 0 10px;
}
.movie-play-item>div:first-child>div .van-count-down {
  color: #fff;
  font-size: 25px;
}
.movie-play-item>div:nth-child(2) p{
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  word-break: break-all;
  overflow: hidden;
  color: #000;
}
.movie-play-item>div:nth-child(2) span{
  color: #000;
}
.movie-play-item>div:nth-child(2) {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

.vjs-big-play-button .vjs-icon-placeholder {
  font-size: 1.63em !important;
}
video::-webkit-media-controls-fullscreen-button {
     display: none;
}
.noVip /deep/.vjs-fullscreen-control{
  display: none;
}


.errorMoney{
width: 80%;
.header{
  // height: 80px;
  // line-height: 80px;
  text-align: center;
  color: #fff;
  padding: 30px;
  background: #b47d9c;
  position: relative;
  .close{
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0px,-50%);
  }
}
.content{
color: #7e5678;
// height: 200px;
  padding: 30px;
}
}
</style>